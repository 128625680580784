import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Button, DatePicker, Radio, Row, Col, Table, Space, Modal, Spin, message } from 'antd'
import _ from 'lodash'

import IdPhotoModal from './components/IdPhotoModal'
import OpenModal from './components/OpenModal'
import AccountModal from './components/AccountModal'
import UpdateBaseModal from './components/UpdateBaseModal'
import BatchImportByFileModal from './components/BatchImportByFileModal'
import store from '../../../../store/index'
import { getPersonalLists, changePersonalStatus, getSign } from '../api'
import { getCityList, getCountyList, getRelevanceCompany, getTaxEnterpriseList } from '../../../../api/api'
import { request } from '../../../../utils/fetch'

import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const labelStyleObj = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
}
const itemStyle = { width: '364px', marginRight: '15px' }
const pageSize = 20
const PersonalLists = props => {
    let path = 'platform:StaffBase'
    const [form] = Form.useForm()
    const [radio, setRadio] = useState(10)
    const [reStopVisible, setReStopVisible] = useState(false)
    const [confirmLoading, setConfrimLoading] = useState(false)
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [cityData, setCityData] = useState([])
    const [countyData, seteCountyData] = useState([])
    const [idPhotoVisible, setIdPhotoVisible] = useState(false)
    const [detail, setDetail] = useState({})
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const [openModalVisible, setOpenModalVisible] = useState(false)
    const [companyData, setCompanyData] = useState([])
    const [idNumber, setIdNumber] = useState()
    const [accountVisible, setAccountVisible] = useState(false)
    const [updateBaseVisible, setUpdateBaseVisible] = useState(false)
    const [batchImportFileVisible, setBatchImportFileVisible] = useState(false)

    const initialFormValues = {
        date: null,
        status: undefined, // 状态
        countyIdList: undefined,
        cityIdList: undefined,
        planType: undefined, // 落地方案
        certificationStatusList: undefined, //认证状态
        signStatusList: undefined, // 签署状态
        accountStatus: undefined,
        companyCodeList: undefined,
        source: undefined,
        keyword: '' // 关键字
    }
    // 分页
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    // 启停用信息
    const [reStopModal, setReStopModal] = useState({
        name: '',
        idNumber: '',
        text: '',
        phone: ''
    })
    // 列表字段
    const tableColumns = [
        { title: '注册时间', dataIndex: 'registeredTime', key: 'registeredTime', width: 180, align: 'center' },
        { title: '落地城市', dataIndex: 'cityName', key: 'cityName', align: 'center', width: 100 },
        { title: '落地区县', dataIndex: 'countyName', key: 'countyName', align: 'center', width: 140 },
        { title: '落地方案', dataIndex: 'planTypeName', key: 'planTypeName', align: 'center', width: 100 },
        { title: '财税企业', dataIndex: 'taxEnterpriseName', key: 'taxEnterpriseName', align: 'center', width: 140 },
        { title: '社会信用统一代码', dataIndex: 'socialCreditCode', key: 'socialCreditCode', align: 'center', width: 145 },
        { title: '登记名称', dataIndex: 'licenseCompanyName', key: 'licenseCompanyName', align: 'center', width: 145 },
        { title: '农行开户状态', dataIndex: 'abcstatusName', key: 'abcstatusName', align: 'center', width: 145 },
        { title: '农行开户卡号', dataIndex: 'abcbankNumber', key: 'abcbankNumber', align: 'center', width: 145 },
        {
            title: '关联公司',
            dataIndex: 'relevanceCompanyName',
            key: 'relevanceCompanyName',
            align: 'center',
            width: 140,
            render: relevanceCompanyName => {
                if (relevanceCompanyName) {
                    return relevanceCompanyName.replace(/,/g, '；')
                } else {
                    return ''
                }
            }
        },
        { title: '姓名', dataIndex: 'name', key: 'name', align: 'center', width: 100 },
        { title: '身份证号', dataIndex: 'idNumber', key: 'idNumber', align: 'center', width: 180 },
        { title: '手机号', dataIndex: 'phone', key: 'phone', align: 'center', width: 120 },
        { title: '民族', dataIndex: 'nation', key: 'nation', align: 'center', width: 120 },
        {
            title: '所在地区',
            dataIndex: 'areaName',
            key: 'areaName',
            align: 'center',
            width: 160,
            render: (areaName, record) => (areaName ? record.provinceName + record.spdbCityName + record.areaName : '')
        },
        { title: '所在城市', dataIndex: 'standCityName', key: 'standCityName', align: 'center', width: 120 },
        { title: '身份证地址', dataIndex: 'idNumberAddress', key: 'idNumberAddress', align: 'center', width: 120 },


        { title: '服务企业', dataIndex: 'serviceCompany', key: 'serviceCompany', align: 'center', width: 120 },
        { title: '银行名称', dataIndex: 'bankNameInfo', key: 'bankNameInfo', align: 'center', width: 120 },
        { title: '银行卡号', dataIndex: 'bankNumberInfo', key: 'bankNumberInfo', align: 'center', width: 120 },
        { title: '开户支行', dataIndex: 'bankOpenInfo', key: 'bankOpenInfo', align: 'center', width: 120 },

        { title: '来源', dataIndex: 'sourceName', key: 'sourceName', align: 'center', width: 80 },
        { title: '实名状态', dataIndex: 'certificationStatusName', key: 'certificationStatusName', align: 'center', width: 100 },
        { title: '合同签署状态', dataIndex: 'signStatusName', key: 'signStatusName', align: 'center', width: 120 },
        {
            title: '紧急联系人手机号',
            dataIndex: 'emergencyPhone',
            key: 'emergencyPhone',
            width: 150,
            align: 'center'
        },
        {
            title: '紧急联系人',
            dataIndex: 'emergencyName',
            key: 'emergencyName',
            width: 150,
            align: 'center'
        },
        { title: '办证状态', dataIndex: 'transactStatusName', key: 'transactStatusName', align: 'center', width: 100 },
        { title: '账号状态', dataIndex: 'statusName', key: 'statusName', align: 'center', width: 100 },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 340,
            align: 'center',
            render: record => {
                const control = []
                // store.getState().userReducer.permissions.indexOf(`${path}:electronicCard`) > -1 &&
                //     control.push(
                //         <a key='elh' href='#!' onClick={() => account(record)}>
                //             二类户
                //         </a>
                //     )
                control.push(
                    <a key='0' href='#!' onClick={() => idPhotoOpen(record)}>
                        身份证照
                    </a>
                )
                store.getState().userReducer.permissions.indexOf(`${path}:downloadZip`) > -1 &&
                    control.push(
                        <a key='1' href='#!' onClick={() => downloadZip(record)}>
                            资料下载
                        </a>
                    )
                record.signStatus === 'GSSB' &&
                    control.push(
                        <a key='3' onClick={() => toSign(record)}>
                            平台签署
                        </a>
                    )
                record.signStatus === 'WC' &&
                    control.push(
                        <a key='3' target='_blank' rel='noopener noreferrer' href={record.pdfViewUrl}>
                            电子合同
                        </a>
                    )
                store.getState().userReducer.permissions.indexOf(`${path}:status`) > -1 &&
                    control.push(
                        record.status === 1 && (
                            <a key='2' href='#!' onClick={() => reStopModalShow(record)}>
                                停用
                            </a>
                        ),
                        record.status === 0 && (
                            <a key='2' href='#!' onClick={() => openModalShow(record)}>
                                启用
                            </a>
                        )
                    )
                store.getState().userReducer.permissions.indexOf(`${path}:updateBase`) > -1 &&
                    control.push(
                        <a key='2' href='#!' onClick={() => updateBase(record)}>
                            修改
                        </a>
                    )

                const result = []
                control.forEach(item => {
                    result.push(item)
                })

                return <Space>{result}</Space>
            }
        }
    ]
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment()
            .subtract(e.target.value - 1, 'days')
            .startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    // 获取城市片区
    const getCity = async () => {
        try {
            const citys = await getCityList()
            setCityData(citys)
        } catch (err) {
            console.log(err)
        }
    }
    // 获取所有财税企业
    const getTaxEnterpriseListall = async () => {
        const res = await getTaxEnterpriseList()
        if (res.data.success) {
            setTaxEnterpriseLis(res.data.data)
        }
    }
    //
    const cityChange = async value => {
        console.log(value)
        form.setFieldsValue({
            countyIdList: []
        })
        seteCountyData([])
        if (!value) return
        try {
            const countyData = await getCountyList({ cityId: value })
            seteCountyData(countyData)
        } catch (err) {
            console.log(err)
        }
    }
    // 获取列表数据
    const getTableLists = async page => {
        const date = form.getFieldsValue().date
        const params = {
            beginTime: date ? moment(date[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endTime: date ? moment(date[1]).format('YYYY-MM-DD HH:mm:ss') : '',
            keyword: form.getFieldsValue().keyword,
            cityIdList: form.getFieldsValue().cityIdList,
            countyIdList: form.getFieldsValue().countyIdList,
            planType: form.getFieldsValue().planType,
            certificationStatusList: form.getFieldsValue().certificationStatusList,
            signStatusList: form.getFieldsValue().signStatusList,
            accountStatus: form.getFieldsValue().accountStatus,
            companyCodeList: form.getFieldsValue().companyCodeList,
            status: form.getFieldsValue().status,
            source: form.getFieldsValue().source,
            // transactStatusList: form.getFieldsValue().transactStatusList,
            taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode,
            pageSize: page.pageSize,
            pageNum: page.pageNum
        }
        try {
            setLoading(true)
            const result = await getPersonalLists(params)
            setLoading(false)
            if (result.data.success) {
                setTableData(result.data.data.list)
                setPagination(prev => {
                    return {
                        ...prev,
                        total: result.data.data.totalCount
                    }
                })
            } else {
                message.error(result.data.retMsg)
            }
        } catch (err) {
            setLoading(false)
            console.log('___个人列表', err)
        }
    }
    // 获取关联公司
    const getCompany = async () => {
        const res = await getRelevanceCompany()
        if (res && res.length > 0) {
            setCompanyData(res)
        }
    }
    useEffect(() => {
        getCity()
        getTaxEnterpriseListall()
        getCompany()
        getTableLists(pagination)
    }, [])

    const submit = () => {
        getTableLists({ pageNum: 1, pageSize: pageSize })
        setPagination(prev => {
            return {
                ...prev,
                pageNum: 1
            }
        })
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio(10)
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    // 二类户
    const account = record => {
        setIdNumber(record.idNumber)
        setAccountVisible(true)
    }
    // 身份证照片
    const idPhotoOpen = record => {
        setDetail(record)
        setIdPhotoVisible(true)
    }
    const idPhotoClose = () => {
        setIdPhotoVisible(false)
    }
    // 二类户关闭
    const accountClose = () => {
        setAccountVisible(false)
    }
    // 平台签署
    const toSign = async record => {
        console.log(record)
        try {
            const res = await getSign({ contractId: record.fddContractId })
            console.log(res)
        } catch (err) {
            console.log(err)
        }
    }
    // 下拉框
    const selectFilter = (inputValue, option) => {
        if (option.children.indexOf(inputValue) != -1) {
            return option
        }
    }
    // 下载资料
    const downloadZip = record => {
        let data = {
            name: record.name,
            idNumber: record.idNumber
        }

        request.downExport('/platform/staffBase/downloadZip', data)
    }
     // 批量导出
    const batchImportlFile = () => {
        setBatchImportFileVisible(true)
    }

    const batchImportFileClose = () => {
        setBatchImportFileVisible(false)
      }
    //
    const rangeChange = dates => {
        if (dates === null) {
            setRadio(10)
        }
    }
    // 停用弹窗
    const reStopModalShow = record => {
        setReStopVisible(true)
        console.log(record)
        setReStopModal({
            name: record.name,
            idNumber: record.idNumber,
            phone: record.phone
        })
    }
    // 启用弹窗
    const openModalShow = record => {
        setDetail(record)
        setOpenModalVisible(true)
    }
    const openModalClose = () => {
        setOpenModalVisible(false)
    }
    // 修改（身份证号，姓名，手机号）
    const updateBase = record => {
        setDetail({ ...record })
        setUpdateBaseVisible(true)
    }
    // 修改（身份证号，姓名，手机号）
    const updateBaseClose = record => {
        setUpdateBaseVisible(false)
    }
    // 启用成功后刷新
    const refresh = () => {
        getTableLists(pagination)
    }
    // 启停用确定
    const reStopOk = async () => {
        const data = { idNumber: reStopModal.idNumber, phone: reStopModal.phone, status: 0 }
        try {
            setConfrimLoading(true)
            const res = await changePersonalStatus(data)
            setConfrimLoading(false)
            if (res.data.success) {
                message.success('停用成功！')
                setReStopVisible(false)
                getTableLists(pagination)
            } else {
                message.error(res.data.retMsg)
            }
        } catch (err) {
            setConfrimLoading(false)
            console.log('__个人列表停用', err)
        }
    }
    const reStopCancel = () => {
        setReStopVisible(false)
    }
    // 导出
    const exportData = () => {
        const date = form.getFieldsValue().date
        const params = {
            beginTime: date ? moment(date[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endTime: date ? moment(date[1]).format('YYYY-MM-DD HH:mm:ss') : '',
            keyword: form.getFieldsValue().keyword,
            cityIdList: form.getFieldsValue().cityIdList,
            countyIdList: form.getFieldsValue().countyIdList,
            planType: form.getFieldsValue().planType,
            certificationStatusList: form.getFieldsValue().certificationStatusList,
            signStatusList: form.getFieldsValue().signStatusList,
            companyCodeList: form.getFieldsValue().companyCodeList,
            accountStatus: form.getFieldsValue().accountStatus,
            source: form.getFieldsValue().source,
            status: form.getFieldsValue().status,
            taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode,
            // transactStatusList: form.getFieldsValue().transactStatusList
        }
        request.downExport('/platform/staffBase/export', params)
    }

    return (
        <Spin spinning={loading}>
            <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
                <Row>
                    <Form.Item style={{ width: '510px' }} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} name='date' label='时间'>
                        <RangePicker
                            allowClear={true}
                            showTime
                            onChange={dates => {
                                rangeChange(dates)
                                setRadio(10)
                            }}
                            format='YYYY-MM-DD HH:mm:ss'
                        />
                    </Form.Item>
                    <Form.Item style={{ width: '600px', marginLeft: '15px' }}>
                        <Radio.Group onChange={radioChange} value={radio}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item style={itemStyle} name='cityIdList' label='落地城市'>
                        <Select allowClear placeholder="全部" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} onChange={value => cityChange(value)}>
                            {cityData.length > 0 &&
                                cityData.map(item => (
                                    <Option value={item.id} key={item.id}>
                                        {item.areaName}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='countyIdList' label='落地区县'>
                        <Select mode='multiple' placeholder="全部" maxTagCount={1} filterOption={(inputValue, option) => selectFilter(inputValue, option)}>
                            {countyData.map(item => (
                                <Option value={item.id} key={item.id}>
                                    {item.areaName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='planType' label='落地方案'>
                        <Select allowClear placeholder="全部">
                            <Option value='1'>临时税务登记</Option>
                            <Option value='2'>个体工商户</Option>
                            <Option value='3'>委托代征</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='companyCodeList' label='关联公司'>
                        <Select mode='multiple' placeholder="全部" maxTagCount={1} filterOption={(inputValue, option) => selectFilter(inputValue, option)} allowClear>
                            {companyData.map(item => (
                                <Option value={item.companyCode} key={item.companyCode}>
                                    {item.companyName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='status' label='账号状态'>
                        <Select allowClear placeholder="全部">
                            <Option value='1'>启用</Option>
                            <Option value='0'>停用</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='source' label='来源'>
                        <Select mode='multiple' placeholder="全部" maxTagCount={1} allowClear>
                            <Option value='1'>注册</Option>
                            <Option value='2'>导入</Option>
                            <Option value='3'>雷神换签</Option>
                            <Option value='4'>雷神入驻</Option>
                            <Option value='5'>雷神转签</Option>
                            <Option value='6'>外部接口同步</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='certificationStatusList' label='实名状态'>
                        <Select allowClear placeholder="全部">
                            <Option value='WRZ'>未认证</Option>
                            <Option value='RZSB'>认证失败</Option>
                            <Option value='RZCG'>认证成功</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='signStatusList' label='合同签署状态'>
                        <Select allowClear placeholder="全部">
                            <Option value='DDYH'>等待个人签署</Option>
                            <Option value='DDGS'>等待平台签署</Option>
                            <Option value='YHSB'>个人签署失败</Option>
                            <Option value='GSSB'>平台签署失败</Option>
                            <Option value='WC'>双方签署完成</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='accountStatus' label='农行开户状态'>
                        <Select allowClear placeholder="全部">
                            <Option value='1'>已开户</Option>
                            <Option value='0'>未开户</Option>
                        </Select>
                    </Form.Item>
                    {/* <Form.Item style={itemStyle} name='transactStatusList' label='办证状态'>
                        <Select mode='multiple' placeholder="全部" allowClear>
                            <Option value='BZDS'>视频待审</Option>
                            <Option value='DDBL'>等待办理</Option>
                            <Option value='BZSHJJ'>视频审核拒绝</Option>
                            <Option value='BZSHTG'>视频审核通过</Option>
                            <Option value='YBL'>已办理</Option>
                            <Option value='ZXDS'>注销待审</Option>
                            <Option value='ZXSHTG'>注销审核通过</Option>
                            <Option value='ZXSHJJ'>注销审核拒绝</Option>
                            <Option value='YZX'>已注销</Option>
                        </Select>
                    </Form.Item> */}
                    <Form.Item style={itemStyle} name='taxEnterpriseCode' label='财税企业'>
                        <Select allowClear placeholder="全部">
                            {TaxEnterpriseLis.map(e => (
                                <Option key={e.code} value={e.code}>
                                    {e.enterpriseName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='keyword' label='关键字'>
                        <Input placeholder='姓名丨身份证号丨手机号 | 所在城市' />
                    </Form.Item>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button type='primary' onClick={submit}>
                                查询
                            </Button>
                            <Button onClick={resetForm}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button onClick={exportData}>导出</Button>}
                            {store.getState().userReducer.permissions.indexOf(`${path}:downloadZip`) > -1 && <Button onClick={batchImportlFile}>批量下载</Button>}
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Table
                columns={tableColumns}
                dataSource={tableData}
                scroll={{ x: 1600, y: 460 }}
                rowKey={record => record.id}
                pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: false,
                    total: pagination.total,
                    current: pagination.pageNum,
                    pageSize: pagination.pageSize,
                    showTotal: total => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
                }}
            />

            {/* 启停用modal */}
            <Modal maskClosable={false} title='提示框' visible={reStopVisible} confirmLoading={confirmLoading} onCancel={reStopCancel} onOk={reStopOk}>
                <div>姓名：{reStopModal.name}</div>
                <div>身份证：{reStopModal.idNumber}</div>
                <div>确定对该用户登录账号进行停用操作么？</div>
            </Modal>
            <OpenModal visible={openModalVisible} close={openModalClose} modalData={detail} companyData={companyData} refresh={refresh} />
            <IdPhotoModal visible={idPhotoVisible} close={idPhotoClose} modalData={detail} refresh={refresh} />
            <AccountModal visible={accountVisible} close={accountClose} idNumber={idNumber} />
            <UpdateBaseModal visible={updateBaseVisible} close={updateBaseClose} modalData={detail} refresh={refresh} />
            <BatchImportByFileModal
                visible={batchImportFileVisible}
                close={batchImportFileClose}
                refresh={refresh}
            />
        </Spin>
    )
}

export default PersonalLists
