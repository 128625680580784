import React, { useState, useEffect, useRef } from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Radio,
  Row,
  Col,
  Table,
  Space,
  Modal,
  message,
  Spin,
} from 'antd'
import { getCityList, getCountyList, getRelevanceCompany, getTaxEnterpriseList } from '../../../../api/api'
import { getDocumentLists } from '../api'
import { request } from '../../../../utils/fetch'

import moment from 'moment'

import AuditModal from './components/AuditModal'
import VideoAuditModal from './components/VideoAuditModal'
import ConfirmAudit from './components/ConfirmAudit'
import UploadModal from './components/UploadModal'
import CheckModal from './components/CheckModal'
import ConfirmCancel from './components/ConfirmCancel'
import BatchTransact from './components/BatchTransact'
import BatchCancel from './components/BatchCancel'
import BatchUpload from './components/BatchUpload'
import BatchExportModal from './components/BatchExportModal'
import BatchSMSModal from './components/BatchSMSModal'
import BatchCancelByFileModal from './components/BatchCancelByFileModal'
import store from '../../../../store/index'
const { Option } = Select
const { RangePicker } = DatePicker
const labelStyleObj = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
}
const itemStyle = { width: '364px', marginRight: '15px' }

const DocumentSave = (props) => {
  let path = 'platform:StaffKeep'
  let path1 = 'platform:StaffBase'
  const [form] = Form.useForm()
  const [radio, setRadio] = useState(10)
  const MoelRef = useRef();
  const SMSMoelRef = useRef();
  const [reStopVisible, setReStopVisible] = useState(false)
  const [confirmLoading, setConfrimLoading] = useState(false)
  const [selectedRowKeys, setSelectRowKeys] = useState([])
  const [cityData, setCityData] = useState([])
  const [countyData, seteCountyData] = useState([])
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false) // 页面loading
  const [auditVisible, setAuditVisible] = useState(false) // 办证审核弹窗visible
  const [videoAuditVisible, setVideoAuditVisible] = useState(false) // 办证审核弹窗visible
  const [detail, setDetail] = useState({})
  const [confirmAuditVisible, setConfirmAuditVisible] = useState(false)
  const [uploadVisible, setUploadVisible] = useState(false)
  const [checkVisible, setCheckVisible] = useState(false)
  const [cancelAuditVisible, setCancelAuditVisible] = useState(false)
  const [auditTitle, setAuditTitle] = useState()
  const [auditType, setAuditType] = useState()
  const [timeType, setTimeType] = useState(2) //根据时间类型判断是否有时分秒
  const [batchVisible, setBatchVisible] = useState(false) //批量办理弹窗
  const [batchCancelVisible, setBatchCancelVisible] = useState(false)
  const [batchCancelFileVisible, setBatchCancelFileVisible] = useState(false)
  const [batchData, setBatchData] = useState({})
  const [batchUploadVisible, setBatchUploadVisible] = useState(false)
  const [companyData, setCompanyData] = useState([]) //关联公司列表
  const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
  const initialFormValues = {
    date: [],
    timeType: 2,
    status: undefined,  // 状态
    countyIdList: undefined,
    cityIdList: undefined,
    planType: undefined, // 落地方案
    transactStatusList: undefined, // 办证状态
    keepStatus: undefined, // 存档状态
    keyword: '', // 关键字
    companyCodeList: undefined,
  }
  const pageSize = 20
  // 分页
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  // 启停用信息
  const [reStopModal, setReStopModal] = useState({
    name: '',
    idNumber: '',
    text: ''
  })

  // 列表字段
  const tableColumns = [
    {
      title: '双方签署完成时间',
      dataIndex: 'signTime',
      key: 'signTime',
      width: 180,
      align: 'center'
    }, {
      title: '成立日期',
      dataIndex: 'establishDate',
      key: 'establishDate',
      align: 'center',
      width: 100
    }, {
      title: '落地城市',
      dataIndex: 'cityName',
      key: 'cityName',
      align: 'center',
      width: 100
    }, {
      title: '落地区县',
      dataIndex: 'countyName',
      key: 'countyName',
      align: 'center',
      width: 140
    }, {
      title: '落地方案',
      dataIndex: 'planTypeName',
      key: 'planTypeName',
      align: 'center',
      width: 100
    },
    {
      title: '财税企业',
      dataIndex: 'taxEnterpriseName',
      key: 'taxEnterpriseName',
      align: 'center',
      width: 150
    },{
      title: '社会信用统一代码',
      dataIndex: 'socialCreditCode',
      key: 'socialCreditCode',
      align: 'center',
      width: 160
    },{
      title: '登记名称',
      dataIndex: 'licenseCompanyName',
      key: 'licenseCompanyName',
      align: 'center',
      width: 160
    }, {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 100
    }, {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 100
    }, {
      title: '身份证号',
      dataIndex: 'idNumber',
      key: 'idNumber',
      align: 'center',
      width: 180
    }, {
      title: '关联公司',
      dataIndex: 'relevanceCompanyName',
      key: 'relevanceCompanyName',
      align: 'center',
      width: 180
    }, {
      title: '办证状态',
      dataIndex: 'transactStatusName',
      key: 'transactStatusName',
      align: 'center',
      width: 120
    }, {
      title: '存档状态',
      key: 'keepStatusName',
      align: 'center',
      width: 100,
      render: (record) => { 
        return record.planType==2?record.keepStatusName:''
      }
    }, {
      title: '操作',
      key: 'control',
      fixed: 'right',
      width: 260,
      align: 'center',
      render: (record) => {
        const control = []
        store.getState().userReducer.permissions.indexOf(`${path}:audit`)>-1&&record.transactStatus === 'BZDS' && control.push(<a key="0" onClick={() => audit(record)}>视频审核</a>)
        // store.getState().userReducer.permissions.indexOf(`${path}:uploadTransactPicture`)>-1&&control.push(<a key="1" onClick={() => uploadShow(record)}>上传</a>)
        store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1&&control.push(<a key="1" onClick={() => uploadShow(record)}>修改</a>)
        store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&control.push(<a key="2" onClick={() => checkShow(record)}>查看</a>)
        if(store.getState().userReducer.permissions.indexOf(`${path}:batchTransact`)>-1){
          record.transactStatus === 'DDBL' && control.push(<a key="3" onClick={() => confirmAudit(record)}>确认办理</a>)
          record.transactStatus === 'BZSHTG' && control.push(<a key="3" onClick={() => confirmAudit(record)}>确认办理</a>)
        }
        store.getState().userReducer.permissions.indexOf(`${path}:audit`)>-1&&record.planType === 2 && record.transactStatus === 'ZXDS' && control.push(<a key="3" onClick={() => cancelWait(record)}>注销审核</a>)
        if(store.getState().userReducer.permissions.indexOf(`${path}:batchCancel`)>-1){
          (record.planType === 1||record.planType === 2) && (record.transactStatus === 'ZXDS'||record.transactStatus === 'YBL') && control.push(<a key="3" onClick={() => cancelConfirm(record)}>确认注销</a>)
        }
        const result = []
        control.forEach(item => {
          result.push(item)
        })

        return (
          <Space>{result}</Space>
        )
      }
    }
  ]
  // 获取列表数据
  const getTableLists = async (page) => {
    const date = form.getFieldsValue().date
    let beginTime = ''
    let endTime = ''
    if (date.length !== 0) {
      beginTime = form.getFieldsValue().timeType == 1 ? moment(date[0]).format('YYYY-MM-DD') : moment(date[0]).format('YYYY-MM-DD HH:mm:ss')
      endTime = form.getFieldsValue().timeType == 1 ? moment(date[1]).format('YYYY-MM-DD') : moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
    }

    const data = {
      timeType: form.getFieldsValue().timeType,
      beginTime: beginTime,
      endTime: endTime,
      keyword: form.getFieldsValue().keyword,
      cityIdList: form.getFieldsValue().cityIdList,
      countyIdList: form.getFieldsValue().countyIdList,
      planType: form.getFieldsValue().planType,
      transactStatusList: form.getFieldsValue().transactStatusList,
      keepStatus: form.getFieldsValue().keepStatus,
      smsStatus: form.getFieldsValue().smsStatus,
      companyCodeList: form.getFieldsValue().companyCodeList,
      taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode,
      pageNum: page.pageNum,
      pageSize: page.pageSize
    }
    try {
      setLoading(true)
      const res = await getDocumentLists(data)
      if (res.data.success) {
        setTableData(res.data.data.list)
        setPagination(prev => {
          return {
            ...prev,
            total: res.data.data.totalCount
          }
        })
      } else {
        message.error(res.data.retMsg)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  // 时间radio选择
  const radioChange = e => {
    let startDate, endDate
    setRadio(e.target.value)
    if (timeType == 1) {
      startDate = moment().subtract(e.target.value - 1, 'days')
    } else {
      startDate = moment().subtract(e.target.value - 1, 'days').startOf('day')
    }

    endDate = moment()
    form.setFieldsValue({ date: [startDate, endDate] })
  }
  // 
  const rangeChange = (dates) => {
    if (dates === null) {
      setRadio(10)
    }
  }
  // 获取城市片区
  const getCity = async () => {
    try {
      const citys = await getCityList()
      setCityData(citys)
    } catch (err) {
      console.log(err)
    }
  }
  // 获取所有财税企业
  const getTaxEnterpriseListall = async () => {
    const res = await getTaxEnterpriseList()
    if (res.data.success) {
      setTaxEnterpriseLis(res.data.data)
    }
  }
  const getCompany = async () => {
    const res = await getRelevanceCompany()
    if (res && res.length > 0) {
      setCompanyData(res)
    }
  }

  // 下拉框
  const selectFilter = (inputValue, option) => {
    if (option.children.indexOf(inputValue) != -1) {
      return option
    }
  }

  // 批量导出
  const batchExport = () => {
    MoelRef.current.showModal()
  }
  // 批量短信
  const batchSMS = () => {
    SMSMoelRef.current.showModal()
  }
  // 
  const cityChange = async (value) => {
    console.log(value)

    form.setFieldsValue({
      countyIdList: []
    })
    seteCountyData([])
    if (!value) return
    try {
      const countyData = await getCountyList({ cityId: value })
      seteCountyData(countyData)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getCompany()
    getCity()
    getTaxEnterpriseListall()
    getTableLists(pagination)
  }, [])
  const submit = () => {
    getTableLists({ pageNum: 1, pageSize: pageSize })
    setPagination(prev => {
      return {
        ...prev,
        pageNum: 1
      }
    })
  }
  // 重置
  const resetForm = () => {
    // console.log(form.getFieldsValue().date)
    form.resetFields()
    setRadio(10)
  }
  //分页改变
  const tablePageChange = (page, pageSize) => {
    getTableLists({ pageNum: page, pageSize: pageSize })
    setPagination(prev => {
      return {
        ...prev,
        pageNum: page
      }
    })
  }
  // 上传
  const uploadShow = (record) => {
    setUploadVisible(true)
    setDetail(record)
  }
  // 办证审核
  const audit = (record) => {
    setAuditVisible(true)
    setAuditTitle('办证审核')
    setAuditType(1)
    setDetail(record)
  }
  // 视频审核
  const videoAudit = (record) => {
    setVideoAuditVisible(true)
    setAuditTitle('视频审核')
    setAuditType(2)
    setDetail(record)
  }
  // 确认办理
  const confirmAudit = (record) => {
    console.log(record)
    if(record.planType==2&&record.keepStatus=='WSC'){
      message.error('未上传营业执照，请先上传营业执照！')
      return
    }
    setConfirmAuditVisible(true)
    setDetail(record)
  }
  // 
  const checkShow = (record) => {
    setCheckVisible(true)
    setDetail(record)
  }
  // 确认注销
  const cancelConfirm = (record) => {
    setCancelAuditVisible(true)
    setDetail(record)
  }
  // 注销审核
  const cancelWait = (record) => {
    setAuditVisible(true)
    setAuditTitle('注销审核')
    setAuditType(2)
    setDetail(record)
  }
  // 启停用弹窗
  const reStopModalShow = (record) => {
    setReStopVisible(true)
    console.log(record)
    setReStopModal({
      name: record.companyName,
      // idNumber: record.idNumber,
      // text: record.status === 1 ? '停用' : '启用'
    })
  }
  const reStopCancel = () => {
    setReStopVisible(false)
  }
  //
  const onSelectChange = (selectedRowKeys, record) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys, record)
    setSelectRowKeys(selectedRowKeys)
    setBatchData(record)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  // 关闭办证审核弹窗
  const close = () => {
    setAuditVisible(false)
  }
  // 关闭视频审核弹窗
  const videoClose = () => {
    setVideoAuditVisible(false)
  }
  const confirmAuditClose = () => {
    setConfirmAuditVisible(false)
  }
  const uploadClose = () => {
    setUploadVisible(false)
  }
  const checkClose = () => {
    setCheckVisible(false)
  }
  const cancelAuditClose = () => {
    setCancelAuditVisible(false)
  }
  const batchClose = () => {
    setBatchVisible(false)
  }
  const batchCancelClose = () => {
    setBatchCancelVisible(false)
  }
  const batchCancelFileClose = () => {
    setBatchCancelFileVisible(false)
  }
  const batchUploadClose = () => {
    setBatchUploadVisible(false)
  }
  // 刷新列表
  const refresh = () => {
    getTableLists(pagination)
  }
  // 批量办理
  const batchAudit = () => {
    if (!selectedRowKeys.length) {
      message.warning('请先勾选需要办理的数据')
      return
    }
    setBatchVisible(true)
  }
  // 批量注销
  const batchCancel = () => {
    if (!selectedRowKeys.length) {
      message.warning('请先勾选需要注销的数据')
      return
    }
    setBatchCancelVisible(true)
  }
  // 批量导入
  const batchUpload = () => {
    setBatchUploadVisible(true)
  }
  // 批量注销导入
  const batchCancelFile = () => {
    setBatchCancelFileVisible(true)
  }
  // 导出
  const exportData = () => {
    let date = form.getFieldsValue().date
    let beginTime = ''
    let endTime = ''
    console.log(date);
    if (date.length !== 0) {
      beginTime = form.getFieldsValue().timeType == 1 ? moment(date[0]).format('YYYY-MM-DD') : moment(date[0]).format('YYYY-MM-DD HH:mm:ss')
      endTime = form.getFieldsValue().timeType == 1 ? moment(date[1]).format('YYYY-MM-DD') : moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    const params = {
      timeType: form.getFieldsValue().timeType,
      beginTime: beginTime,
      endTime: endTime,
      keyword: form.getFieldsValue().keyword,
      cityIdList: form.getFieldsValue().cityIdList,
      countyIdList: form.getFieldsValue().countyIdList,
      planType: form.getFieldsValue().planType,
      transactStatusList: form.getFieldsValue().transactStatusList,
      keepStatus: form.getFieldsValue().keepStatus,
      taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode,
      companyCodeList: form.getFieldsValue().companyCodeList,
    }
    request.downExport('/platform/staffKeep/export', params)
  }
  return (
    <Spin spinning={loading}>
      <Form labelCol={{ span: 6 }} initialValues={initialFormValues} form={form} {...labelStyleObj}>
        <Row>
          <Form.Item style={{ width: '160px', marginLeft: '38px' }} name="timeType" wrapperCol={24}>
            <Select onSelect={(value) => { setTimeType(value); setRadio(10); form.setFieldsValue({ date: [] }) }}>
              <Option value={1}>成立日期</Option>
              <Option value={2}>双方签署完成时间</Option>
            </Select>
          </Form.Item>
          <Form.Item name="date" >
            <RangePicker style={{ width: '340px' }} onChange={(dates) => rangeChange(dates)} showTime={timeType == 2} format={timeType == 1 ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'} />
          </Form.Item>
          <Form.Item style={{ width: '600px', marginLeft: '15px' }}>
            <Radio.Group onChange={radioChange} value={radio} >
              <Radio value={1}>今天</Radio>
              <Radio value={7}>近7天</Radio>
              <Radio value={30}>近30天</Radio>
            </Radio.Group>
          </Form.Item>
        </Row>
        <Row>
          <Form.Item style={itemStyle} name="cityIdList" label="落地城市">
            <Select allowClear onChange={(value) => cityChange(value)} placeholder="全部">
              {cityData.length > 0 && cityData.map(item => (
                <Option value={item.id} key={item.id}>{item.areaName}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={itemStyle} name="countyIdList" label="落地区县">
            <Select mode="multiple" maxTagCount={1} placeholder="全部">
              {countyData.map(item => (
                <Option value={item.id} key={item.id}>{item.areaName}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={itemStyle} name="planType" label="落地方案">
            <Select allowClear placeholder="全部">
              <Option value="1">临时税务登记</Option>
              <Option value="2">个体工商户</Option>
              <Option value="3">委托代征</Option>
            </Select>
          </Form.Item>
          <Form.Item style={itemStyle} name="transactStatusList" label="证件状态">
            <Select mode="multiple" allowClear placeholder="全部">
              <Option value="BZDS">视频待审</Option>
              <Option value="DDBL">等待办理</Option>
              <Option value="BZSHJJ">视频审核拒绝</Option>
              <Option value="BZSHTG">视频审核通过</Option>
              <Option value="YBL">已办理</Option>
              <Option value="ZXDS">注销待审</Option>
              <Option value="ZXSHTG">注销审核通过</Option>
              <Option value="ZXSHJJ">注销审核拒绝</Option>
              <Option value="YZX">已注销</Option>
            </Select>
          </Form.Item>
          <Form.Item style={itemStyle} name="companyCodeList" label="关联公司">
            <Select mode="multiple" maxTagCount={1} filterOption={(inputValue, option) => selectFilter(inputValue, option)} allowClear placeholder="全部">
              {
                companyData.map(item => (
                  <Option value={item.companyCode} key={item.companyCode}>{item.companyName}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item style={itemStyle} name="keepStatus" label="存档状态">
            <Select allowClear placeholder="全部">
              <Option value="WSC">未上传</Option>
              <Option value="YSC">已上传</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item style={itemStyle} name="smsStatus" label="短信状态">
            <Select allowClear placeholder="全部">
              <Option value={0}>未发送</Option>
              <Option value={1}>发送成功</Option>
              <Option value={2}>发送失败</Option>
            </Select>
          </Form.Item> */}
          <Form.Item style={itemStyle} name="taxEnterpriseCode" label="财税企业">
            <Select allowClear placeholder="全部">
              {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item style={itemStyle} name="keyword" label="关键字">
            <Input placeholder="姓名丨身份证号" />
          </Form.Item>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Space>
              <Button type="primary" onClick={submit}>查询</Button>
              <Button onClick={resetForm}>重置</Button>
              {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button onClick={exportData}>导出</Button>}
              {store.getState().userReducer.permissions.indexOf(`${path}:batchExport`) > -1 && <Button onClick={batchExport}>批量导出</Button>}
              {store.getState().userReducer.permissions.indexOf(`${path}:batchSMS`) > -1 && <Button onClick={batchSMS}>批量短信</Button>}
              {store.getState().userReducer.permissions.indexOf(`${path}:batchTransact`) > -1 && <Button onClick={batchAudit}>批量办理</Button>}
              {store.getState().userReducer.permissions.indexOf(`${path}:batchCancel`) > -1 && <Button onClick={batchCancel}>批量注销</Button>}
              {store.getState().userReducer.permissions.indexOf(`${path}:batchTransactByFile`) > -1 && <Button onClick={batchUpload}>批量办理导入</Button>}
              {store.getState().userReducer.permissions.indexOf(`${path}:batchCancel`) > -1 && <Button onClick={batchCancelFile}>批量注销导入</Button>}
            </Space>
          </Col>
        </Row>
      </Form>
      <Table
        columns={tableColumns}
        dataSource={tableData}
        scroll={{ x: 1600, y: 500 }}
        rowSelection={rowSelection}
        rowKey={record => record.keepNo}
        pagination={{
          showSizeChanger: false,
          position: ['bottomCenter'],
          total: pagination.total,
          current: pagination.pageNum,
          pageSize: pagination.pageSize,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page, pageSize) => tablePageChange(page, pageSize)
        }}
      />

      {/* 启停用modal */}
      <Modal maskClosable={false}
        title="提示框"
        visible={reStopVisible}
        confirmLoading={confirmLoading}
        onCancel={reStopCancel}
      >
        <div>姓名：{reStopModal.name}</div>
        <div>身份证：{reStopModal.idNumber}</div>
      </Modal>
      {/* 办证审核 */}
      <AuditModal
        visible={auditVisible}
        close={close}
        auditType={auditType}
        title={auditTitle}
        modalData={detail}
        refresh={refresh}
      />
      {/* 视频审核 */}
      <VideoAuditModal
        visible={videoAuditVisible}
        close={videoClose}
        auditType={auditType}
        title={auditTitle}
        modalData={detail}
        refresh={refresh}
      />
      <ConfirmAudit
        visible={confirmAuditVisible}
        close={confirmAuditClose}
        modalData={detail}
        refresh={refresh}
      />
      <UploadModal
        visible={uploadVisible}
        close={uploadClose}
        modalData={detail}
        refresh={refresh}
      />
      <CheckModal
        visible={checkVisible}
        close={checkClose}
        modalData={detail}
        refresh={refresh}
      />
      <ConfirmCancel
        visible={cancelAuditVisible}
        close={cancelAuditClose}
        modalData={detail}
        refresh={refresh}
      />
      <BatchTransact
        visible={batchVisible}
        close={batchClose}
        modalData={batchData}
        refresh={refresh}
      />
      <BatchCancel
        visible={batchCancelVisible}
        close={batchCancelClose}
        modalData={batchData}
        refresh={refresh}
      />
      <BatchCancelByFileModal
        visible={batchCancelFileVisible}
        close={batchCancelFileClose}
        refresh={refresh}
      />
      <BatchUpload
        visible={batchUploadVisible}
        close={batchUploadClose}
        refresh={refresh}
      />
      <BatchExportModal
        ref={MoelRef}
        refresh={refresh}
      />
      <BatchSMSModal
        ref={SMSMoelRef}
        refresh={refresh}
      />
    </Spin>
  )
}

export default DocumentSave