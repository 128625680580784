import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  message,
  Upload
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'


function BatchCancelByFileModal(props) {
  const { visible, close, refresh } = props
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [fileList, setFileList] = useState([])

  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  useEffect(() => {
    if (visible) {
      setFileList([])
    }
  }, [visible])
  const downloadUrl =  `/wispay/common/getTemplateDownloadUrl?type=PT_XZSFZ&token=${uploadObj.token}&mobile=${uploadObj.mobile}`
  const beforeUpload = (file) => {
    console.log(file)
    setFileList([file])
    return false
  }
  const handleRemove = (file) => {
    setFileList([])
  }
  const onCancel = () => {
    close()
  }
  const confirm = async() => {
    try {
      if (fileList.length < 1) {
        message.warning('请先上传文件')
        return
      }
      const formData = new FormData()
      formData.append('token', uploadObj.token)
      formData.append('mobile', uploadObj.mobile)
      fileList.forEach(file => {
        formData.append('file', file)
      })
      setConfirmLoading(true)
      axios({
        method: 'post',
        url: `/wispay/platform/staffBase/batchDownloadZip`,
        data: formData,
      }).then(res => {
        if (res.data.success){
          message.success('请求成功')
          close()
          refresh()
        } else {
            let errArry = res.data.retMsg.trim().split('\n')
            errArry = errArry.filter(item => item.length > 0)
            let node = errArry.map(item => {
                return (
                    <span key={item}>
                        {item}
                        <br />
                    </span>
                )
            })
            message.error(node)
        }
        setConfirmLoading(false)     
      })
    } catch(err) {
      setConfirmLoading(false)
      console.log(err)
    }
  }
  return (
    <Modal maskClosable={false} title="批量下载" visible={visible} confirmLoading={confirmLoading} onCancel={onCancel} onOk={confirm} width={800}>
      <div  style={{display: 'flex'}}>
        <Upload  
          beforeUpload={beforeUpload}
          maxCount="1"
          fileList={fileList}
          onRemove={handleRemove}
          name="file">
          <Button type="primary" icon={<UploadOutlined />}>上传</Button>
        </Upload>
        <Button type="link" href={downloadUrl}>下载模板</Button>
      </div>
    </Modal>
  )
  
}

export default BatchCancelByFileModal